/*
 * Suvorov Andrey - Bootstrap based, Lixpen template.
 *
 */
html,
button,
input,
select,
textarea {
  color: #323232; }

body {
  margin: 0;
  padding: 0;
  font-size: 1.1em;
  font-weight: 300;
  line-height: 1.4;
  font-family: "Open Sans", "Helvetica", sans-serif;
  background-color: #fafafa; }

a {
  text-decoration: none;
  color: #323232; }
  a:visited {
    color: #323232; }
  a:hover {
    color: #df5a49; }

h1, h2, h3, h4 {
  text-transform: uppercase;
  font-size: 1.7em;
  font-weight: 300;
  padding: 0;
  margin: 0 0 15px 0;
  color: #323232; }
  h1 b, h2 b, h3 b, h4 b {
    font-weight: 400; }

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

.container {
  margin-right: auto;
  margin-left: auto;
  padding-left: 15px;
  padding-right: 15px; }
  .container:before, .container:after {
    content: " ";
    display: table; }
  .container:after {
    clear: both; }
  @media (min-width: 768px) {
    .container {
      width: 750px; } }
  @media (min-width: 992px) {
    .container {
      width: 970px; } }
  @media (min-width: 1200px) {
    .container {
      width: 1170px; } }

.container-fluid {
  margin-right: auto;
  margin-left: auto;
  padding-left: 15px;
  padding-right: 15px; }
  .container-fluid:before, .container-fluid:after {
    content: " ";
    display: table; }
  .container-fluid:after {
    clear: both; }

.row {
  margin-left: -15px;
  margin-right: -15px; }
  .row:before, .row:after {
    content: " ";
    display: table; }
  .row:after {
    clear: both; }

.col-xs-1, .col-sm-1, .col-md-1, .col-lg-1, .col-xs-2, .col-sm-2, .col-md-2, .col-lg-2, .col-xs-3, .col-sm-3, .col-md-3, .col-lg-3, .col-xs-4, .col-sm-4, .col-md-4, .col-lg-4, .col-xs-5, .col-sm-5, .col-md-5, .col-lg-5, .col-xs-6, .col-sm-6, .col-md-6, .col-lg-6, .col-xs-7, .col-sm-7, .col-md-7, .col-lg-7, .col-xs-8, .col-sm-8, .col-md-8, .col-lg-8, .col-xs-9, .col-sm-9, .col-md-9, .col-lg-9, .col-xs-10, .col-sm-10, .col-md-10, .col-lg-10, .col-xs-11, .col-sm-11, .col-md-11, .col-lg-11, .col-xs-12, .col-sm-12, .col-md-12, .col-lg-12 {
  position: relative;
  min-height: 1px;
  padding-left: 15px;
  padding-right: 15px; }

.col-xs-1, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9, .col-xs-10, .col-xs-11, .col-xs-12 {
  float: left; }

.col-xs-1 {
  width: 8.3333333333%; }

.col-xs-2 {
  width: 16.6666666667%; }

.col-xs-3 {
  width: 25%; }

.col-xs-4 {
  width: 33.3333333333%; }

.col-xs-5 {
  width: 41.6666666667%; }

.col-xs-6 {
  width: 50%; }

.col-xs-7 {
  width: 58.3333333333%; }

.col-xs-8 {
  width: 66.6666666667%; }

.col-xs-9 {
  width: 75%; }

.col-xs-10 {
  width: 83.3333333333%; }

.col-xs-11 {
  width: 91.6666666667%; }

.col-xs-12 {
  width: 100%; }

.col-xs-pull-0 {
  right: auto; }

.col-xs-pull-1 {
  right: 8.3333333333%; }

.col-xs-pull-2 {
  right: 16.6666666667%; }

.col-xs-pull-3 {
  right: 25%; }

.col-xs-pull-4 {
  right: 33.3333333333%; }

.col-xs-pull-5 {
  right: 41.6666666667%; }

.col-xs-pull-6 {
  right: 50%; }

.col-xs-pull-7 {
  right: 58.3333333333%; }

.col-xs-pull-8 {
  right: 66.6666666667%; }

.col-xs-pull-9 {
  right: 75%; }

.col-xs-pull-10 {
  right: 83.3333333333%; }

.col-xs-pull-11 {
  right: 91.6666666667%; }

.col-xs-pull-12 {
  right: 100%; }

.col-xs-push-0 {
  left: auto; }

.col-xs-push-1 {
  left: 8.3333333333%; }

.col-xs-push-2 {
  left: 16.6666666667%; }

.col-xs-push-3 {
  left: 25%; }

.col-xs-push-4 {
  left: 33.3333333333%; }

.col-xs-push-5 {
  left: 41.6666666667%; }

.col-xs-push-6 {
  left: 50%; }

.col-xs-push-7 {
  left: 58.3333333333%; }

.col-xs-push-8 {
  left: 66.6666666667%; }

.col-xs-push-9 {
  left: 75%; }

.col-xs-push-10 {
  left: 83.3333333333%; }

.col-xs-push-11 {
  left: 91.6666666667%; }

.col-xs-push-12 {
  left: 100%; }

.col-xs-offset-0 {
  margin-left: 0%; }

.col-xs-offset-1 {
  margin-left: 8.3333333333%; }

.col-xs-offset-2 {
  margin-left: 16.6666666667%; }

.col-xs-offset-3 {
  margin-left: 25%; }

.col-xs-offset-4 {
  margin-left: 33.3333333333%; }

.col-xs-offset-5 {
  margin-left: 41.6666666667%; }

.col-xs-offset-6 {
  margin-left: 50%; }

.col-xs-offset-7 {
  margin-left: 58.3333333333%; }

.col-xs-offset-8 {
  margin-left: 66.6666666667%; }

.col-xs-offset-9 {
  margin-left: 75%; }

.col-xs-offset-10 {
  margin-left: 83.3333333333%; }

.col-xs-offset-11 {
  margin-left: 91.6666666667%; }

.col-xs-offset-12 {
  margin-left: 100%; }

@media (min-width: 768px) {
  .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12 {
    float: left; }
  .col-sm-1 {
    width: 8.3333333333%; }
  .col-sm-2 {
    width: 16.6666666667%; }
  .col-sm-3 {
    width: 25%; }
  .col-sm-4 {
    width: 33.3333333333%; }
  .col-sm-5 {
    width: 41.6666666667%; }
  .col-sm-6 {
    width: 50%; }
  .col-sm-7 {
    width: 58.3333333333%; }
  .col-sm-8 {
    width: 66.6666666667%; }
  .col-sm-9 {
    width: 75%; }
  .col-sm-10 {
    width: 83.3333333333%; }
  .col-sm-11 {
    width: 91.6666666667%; }
  .col-sm-12 {
    width: 100%; }
  .col-sm-pull-0 {
    right: auto; }
  .col-sm-pull-1 {
    right: 8.3333333333%; }
  .col-sm-pull-2 {
    right: 16.6666666667%; }
  .col-sm-pull-3 {
    right: 25%; }
  .col-sm-pull-4 {
    right: 33.3333333333%; }
  .col-sm-pull-5 {
    right: 41.6666666667%; }
  .col-sm-pull-6 {
    right: 50%; }
  .col-sm-pull-7 {
    right: 58.3333333333%; }
  .col-sm-pull-8 {
    right: 66.6666666667%; }
  .col-sm-pull-9 {
    right: 75%; }
  .col-sm-pull-10 {
    right: 83.3333333333%; }
  .col-sm-pull-11 {
    right: 91.6666666667%; }
  .col-sm-pull-12 {
    right: 100%; }
  .col-sm-push-0 {
    left: auto; }
  .col-sm-push-1 {
    left: 8.3333333333%; }
  .col-sm-push-2 {
    left: 16.6666666667%; }
  .col-sm-push-3 {
    left: 25%; }
  .col-sm-push-4 {
    left: 33.3333333333%; }
  .col-sm-push-5 {
    left: 41.6666666667%; }
  .col-sm-push-6 {
    left: 50%; }
  .col-sm-push-7 {
    left: 58.3333333333%; }
  .col-sm-push-8 {
    left: 66.6666666667%; }
  .col-sm-push-9 {
    left: 75%; }
  .col-sm-push-10 {
    left: 83.3333333333%; }
  .col-sm-push-11 {
    left: 91.6666666667%; }
  .col-sm-push-12 {
    left: 100%; }
  .col-sm-offset-0 {
    margin-left: 0%; }
  .col-sm-offset-1 {
    margin-left: 8.3333333333%; }
  .col-sm-offset-2 {
    margin-left: 16.6666666667%; }
  .col-sm-offset-3 {
    margin-left: 25%; }
  .col-sm-offset-4 {
    margin-left: 33.3333333333%; }
  .col-sm-offset-5 {
    margin-left: 41.6666666667%; }
  .col-sm-offset-6 {
    margin-left: 50%; }
  .col-sm-offset-7 {
    margin-left: 58.3333333333%; }
  .col-sm-offset-8 {
    margin-left: 66.6666666667%; }
  .col-sm-offset-9 {
    margin-left: 75%; }
  .col-sm-offset-10 {
    margin-left: 83.3333333333%; }
  .col-sm-offset-11 {
    margin-left: 91.6666666667%; }
  .col-sm-offset-12 {
    margin-left: 100%; } }

@media (min-width: 992px) {
  .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12 {
    float: left; }
  .col-md-1 {
    width: 8.3333333333%; }
  .col-md-2 {
    width: 16.6666666667%; }
  .col-md-3 {
    width: 25%; }
  .col-md-4 {
    width: 33.3333333333%; }
  .col-md-5 {
    width: 41.6666666667%; }
  .col-md-6 {
    width: 50%; }
  .col-md-7 {
    width: 58.3333333333%; }
  .col-md-8 {
    width: 66.6666666667%; }
  .col-md-9 {
    width: 75%; }
  .col-md-10 {
    width: 83.3333333333%; }
  .col-md-11 {
    width: 91.6666666667%; }
  .col-md-12 {
    width: 100%; }
  .col-md-pull-0 {
    right: auto; }
  .col-md-pull-1 {
    right: 8.3333333333%; }
  .col-md-pull-2 {
    right: 16.6666666667%; }
  .col-md-pull-3 {
    right: 25%; }
  .col-md-pull-4 {
    right: 33.3333333333%; }
  .col-md-pull-5 {
    right: 41.6666666667%; }
  .col-md-pull-6 {
    right: 50%; }
  .col-md-pull-7 {
    right: 58.3333333333%; }
  .col-md-pull-8 {
    right: 66.6666666667%; }
  .col-md-pull-9 {
    right: 75%; }
  .col-md-pull-10 {
    right: 83.3333333333%; }
  .col-md-pull-11 {
    right: 91.6666666667%; }
  .col-md-pull-12 {
    right: 100%; }
  .col-md-push-0 {
    left: auto; }
  .col-md-push-1 {
    left: 8.3333333333%; }
  .col-md-push-2 {
    left: 16.6666666667%; }
  .col-md-push-3 {
    left: 25%; }
  .col-md-push-4 {
    left: 33.3333333333%; }
  .col-md-push-5 {
    left: 41.6666666667%; }
  .col-md-push-6 {
    left: 50%; }
  .col-md-push-7 {
    left: 58.3333333333%; }
  .col-md-push-8 {
    left: 66.6666666667%; }
  .col-md-push-9 {
    left: 75%; }
  .col-md-push-10 {
    left: 83.3333333333%; }
  .col-md-push-11 {
    left: 91.6666666667%; }
  .col-md-push-12 {
    left: 100%; }
  .col-md-offset-0 {
    margin-left: 0%; }
  .col-md-offset-1 {
    margin-left: 8.3333333333%; }
  .col-md-offset-2 {
    margin-left: 16.6666666667%; }
  .col-md-offset-3 {
    margin-left: 25%; }
  .col-md-offset-4 {
    margin-left: 33.3333333333%; }
  .col-md-offset-5 {
    margin-left: 41.6666666667%; }
  .col-md-offset-6 {
    margin-left: 50%; }
  .col-md-offset-7 {
    margin-left: 58.3333333333%; }
  .col-md-offset-8 {
    margin-left: 66.6666666667%; }
  .col-md-offset-9 {
    margin-left: 75%; }
  .col-md-offset-10 {
    margin-left: 83.3333333333%; }
  .col-md-offset-11 {
    margin-left: 91.6666666667%; }
  .col-md-offset-12 {
    margin-left: 100%; } }

@media (min-width: 1200px) {
  .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12 {
    float: left; }
  .col-lg-1 {
    width: 8.3333333333%; }
  .col-lg-2 {
    width: 16.6666666667%; }
  .col-lg-3 {
    width: 25%; }
  .col-lg-4 {
    width: 33.3333333333%; }
  .col-lg-5 {
    width: 41.6666666667%; }
  .col-lg-6 {
    width: 50%; }
  .col-lg-7 {
    width: 58.3333333333%; }
  .col-lg-8 {
    width: 66.6666666667%; }
  .col-lg-9 {
    width: 75%; }
  .col-lg-10 {
    width: 83.3333333333%; }
  .col-lg-11 {
    width: 91.6666666667%; }
  .col-lg-12 {
    width: 100%; }
  .col-lg-pull-0 {
    right: auto; }
  .col-lg-pull-1 {
    right: 8.3333333333%; }
  .col-lg-pull-2 {
    right: 16.6666666667%; }
  .col-lg-pull-3 {
    right: 25%; }
  .col-lg-pull-4 {
    right: 33.3333333333%; }
  .col-lg-pull-5 {
    right: 41.6666666667%; }
  .col-lg-pull-6 {
    right: 50%; }
  .col-lg-pull-7 {
    right: 58.3333333333%; }
  .col-lg-pull-8 {
    right: 66.6666666667%; }
  .col-lg-pull-9 {
    right: 75%; }
  .col-lg-pull-10 {
    right: 83.3333333333%; }
  .col-lg-pull-11 {
    right: 91.6666666667%; }
  .col-lg-pull-12 {
    right: 100%; }
  .col-lg-push-0 {
    left: auto; }
  .col-lg-push-1 {
    left: 8.3333333333%; }
  .col-lg-push-2 {
    left: 16.6666666667%; }
  .col-lg-push-3 {
    left: 25%; }
  .col-lg-push-4 {
    left: 33.3333333333%; }
  .col-lg-push-5 {
    left: 41.6666666667%; }
  .col-lg-push-6 {
    left: 50%; }
  .col-lg-push-7 {
    left: 58.3333333333%; }
  .col-lg-push-8 {
    left: 66.6666666667%; }
  .col-lg-push-9 {
    left: 75%; }
  .col-lg-push-10 {
    left: 83.3333333333%; }
  .col-lg-push-11 {
    left: 91.6666666667%; }
  .col-lg-push-12 {
    left: 100%; }
  .col-lg-offset-0 {
    margin-left: 0%; }
  .col-lg-offset-1 {
    margin-left: 8.3333333333%; }
  .col-lg-offset-2 {
    margin-left: 16.6666666667%; }
  .col-lg-offset-3 {
    margin-left: 25%; }
  .col-lg-offset-4 {
    margin-left: 33.3333333333%; }
  .col-lg-offset-5 {
    margin-left: 41.6666666667%; }
  .col-lg-offset-6 {
    margin-left: 50%; }
  .col-lg-offset-7 {
    margin-left: 58.3333333333%; }
  .col-lg-offset-8 {
    margin-left: 66.6666666667%; }
  .col-lg-offset-9 {
    margin-left: 75%; }
  .col-lg-offset-10 {
    margin-left: 83.3333333333%; }
  .col-lg-offset-11 {
    margin-left: 91.6666666667%; }
  .col-lg-offset-12 {
    margin-left: 100%; } }

@-ms-viewport {
  width: device-width; }

.visible-xs, .visible-sm, .visible-md, .visible-lg {
  display: none !important; }

.visible-xs-block,
.visible-xs-inline,
.visible-xs-inline-block,
.visible-sm-block,
.visible-sm-inline,
.visible-sm-inline-block,
.visible-md-block,
.visible-md-inline,
.visible-md-inline-block,
.visible-lg-block,
.visible-lg-inline,
.visible-lg-inline-block {
  display: none !important; }

@media (max-width: 767px) {
  .visible-xs {
    display: block !important; }
  table.visible-xs {
    display: table; }
  tr.visible-xs {
    display: table-row !important; }
  th.visible-xs,
  td.visible-xs {
    display: table-cell !important; }
  #header .btn.btn-play {
    width: 100%;
    text-align: center;
    padding: 16px 30px 16px 38px;
    border-radius: 3px 3px 0px 0px;
    border-right: none; }
  #header .btn.btn-black {
    width: 100%;
    text-align: center;
    border-radius: 0px 0px 3px 3px; } }

@media (max-width: 767px) {
  .visible-xs-block {
    display: block !important; } }

@media (max-width: 767px) {
  .visible-xs-inline {
    display: inline !important; } }

@media (max-width: 767px) {
  .visible-xs-inline-block {
    display: inline-block !important; } }

@media (min-width: 768px) and (max-width: 991px) {
  .visible-sm {
    display: block !important; }
  table.visible-sm {
    display: table; }
  tr.visible-sm {
    display: table-row !important; }
  th.visible-sm,
  td.visible-sm {
    display: table-cell !important; }
  #header .btn.btn-play {
    width: 100%;
    text-align: center;
    padding: 16px 30px 16px 38px;
    border-radius: 3px 3px 0px 0px;
    border-right: none; }
  #header .btn.btn-black {
    width: 100%;
    text-align: center;
    border-radius: 0px 0px 3px 3px; } }

@media (min-width: 768px) and (max-width: 991px) {
  .visible-sm-block {
    display: block !important; } }

@media (min-width: 768px) and (max-width: 991px) {
  .visible-sm-inline {
    display: inline !important; } }

@media (min-width: 768px) and (max-width: 991px) {
  .visible-sm-inline-block {
    display: inline-block !important; } }

@media (min-width: 992px) and (max-width: 1199px) {
  .visible-md {
    display: block !important; }
  table.visible-md {
    display: table; }
  tr.visible-md {
    display: table-row !important; }
  th.visible-md,
  td.visible-md {
    display: table-cell !important; } }

@media (min-width: 992px) and (max-width: 1199px) {
  .visible-md-block {
    display: block !important; } }

@media (min-width: 992px) and (max-width: 1199px) {
  .visible-md-inline {
    display: inline !important; } }

@media (min-width: 992px) and (max-width: 1199px) {
  .visible-md-inline-block {
    display: inline-block !important; } }

@media (min-width: 1200px) {
  .visible-lg {
    display: block !important; }
  table.visible-lg {
    display: table; }
  tr.visible-lg {
    display: table-row !important; }
  th.visible-lg,
  td.visible-lg {
    display: table-cell !important; } }

@media (min-width: 1200px) {
  .visible-lg-block {
    display: block !important; } }

@media (min-width: 1200px) {
  .visible-lg-inline {
    display: inline !important; } }

@media (min-width: 1200px) {
  .visible-lg-inline-block {
    display: inline-block !important; } }

@media (max-width: 767px) {
  .hidden-xs {
    display: none !important; } }

@media (min-width: 768px) and (max-width: 991px) {
  .hidden-sm {
    display: none !important; } }

@media (min-width: 992px) and (max-width: 1199px) {
  .hidden-md {
    display: none !important; } }

@media (min-width: 1200px) {
  .hidden-lg {
    display: none !important; } }

.visible-print {
  display: none !important; }

@media print {
  .visible-print {
    display: block !important; }
  table.visible-print {
    display: table; }
  tr.visible-print {
    display: table-row !important; }
  th.visible-print,
  td.visible-print {
    display: table-cell !important; } }

.visible-print-block {
  display: none !important; }
  @media print {
    .visible-print-block {
      display: block !important; } }

.visible-print-inline {
  display: none !important; }
  @media print {
    .visible-print-inline {
      display: inline !important; } }

.visible-print-inline-block {
  display: none !important; }
  @media print {
    .visible-print-inline-block {
      display: inline-block !important; } }

@media print {
  .hidden-print {
    display: none !important; } }

#header {
  position: relative;
  height: 1059px;
  background-color: #d4d4d4;
  background-image: url("../images/header-bg.jpg");
  background-repeat: no-repeat;
  background-position: top center;
  box-shadow: 0 0 300px 0 #fafafa; }
  #header .icon-logo {
    display: block;
    float: left;
    font-size: 53px;
    margin-top: 18px; }
    #header .icon-logo:hover {
      color: #323232; }
  #header .slogan {
    display: block;
    float: left;
    content: "";
    font-size: .8em;
    margin: 35px 0 0 10px;
    text-transform: uppercase; }
  #header .menu {
    font-size: .9em;
    text-align: right;
    margin-top: 35px; }
    #header .menu a {
      margin: 0 5px;
      padding: 0 5px;
      vertical-align: top;
      font-size: 1.2em;
      opacity: .7; }
      #header .menu a:last-child {
        margin-right: 0;
        padding-right: 0; }
    #header .menu .usd {
      text-transform: uppercase;
      background-image: url("../images/sprite.png");
      background-repeat: no-repeat;
      background-position: right 6px; }
      #header .menu .usd .icon-ard {
        margin-left: 2px; }
    #header .menu .icon-cart {
      font-size: 1.3em;
      margin-right: 4px; }
  #header h1 {
    margin-top: 270px; }
  #header .flLix {
    position: absolute;
    margin-left: 15px;
    bottom: 92px;
    width: 441px;
    height: 178px;
    background-image: url("../images/hdflLix.png");
    overflow: visible; }
    #header .flLix .fl {
      margin-left: 324px;
      height: 3px;
      width: 1px;
      background-color: #323232;
      transition: width 2s ease-in-out; }
    #header .flLix .lixCont {
      position: relative;
      widows: 100%; }
      #header .flLix .lixCont .lix {
        position: absolute;
        left: 310px;
        bottom: 0px;
        width: 193px;
        height: 622px;
        background-image: url("../images/hdLix.png");
        transition: left 2s ease-in-out; }
  #header .btns {
    text-align: center;
    margin-top: 540px; }
    #header .btns .kst {
      display: inline-block;
      content: "";
      margin-top: 25px;
      width: 216px;
      height: 39px;
      background-image: url("../images/hdKst.png"); }

.pull-right {
  float: right; }

.btn {
  display: inline-block;
  position: relative;
  padding: 16px;
  font-size: 1em;
  text-transform: uppercase;
  border: none;
  border-radius: 3px;
  color: #fff;
  transition: background 0.2s ease-in; }
  .btn.btn-red {
    background-color: #df5a49;
    color: #fff; }
    .btn.btn-red:hover {
      color: #fff;
      background-color: #d03825; }
  .btn.btn-black {
    background: #323232;
    color: #fff; }
    .btn.btn-black:hover {
      color: #fff;
      background-color: #191919; }
  .btn.btn-play {
    padding: 16px 30px 16px 38px;
    border-radius: 3px 0 0 3px;
    border-right: 1px solid #fff;
    background-image: url("../images/sprite.png");
    background-repeat: no-repeat;
    background-position: 23px 23px; }
  .btn.btn-buy {
    padding: 16px 30px 16px 38px;
    border-radius: 0px 3px 3px 0px; }
    .btn.btn-buy span {
      display: block;
      position: absolute;
      padding: 4px;
      margin: 4px 0 0 0;
      left: -9px;
      background: #fff;
      border-radius: 50%;
      font-size: .5em;
      font-weight: 500;
      line-height: 1em;
      color: #df5a49;
      text-transform: none; }
